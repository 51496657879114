<template>
  <div class="inline-block">
    <div
      class="flex items-center cursor-pointer"
      @click.stop="onToogle"
    >
      <div
        :tabindex="!disabled ? 0 : -1"
        class="el-check block leading-none w-16 h-16 border border-grey-200 rounded-sm bg-white outline-none focus:border-blue focus:shadow-outline-blue"
        :class="{ 'bg-green checked': value, 'disabled bg-grey-100': disabled }"
      />
      <span v-if="$slots.default" :class="['ml-8 leading-none', { 'disabled': disabled }]"><slot /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ElCheckbox',

  props: {
    value: Boolean,
    disabled: { type: Boolean, default: false }
  },

  data () {
    return {
      checked: false
    }
  },

  computed: {
    isReadOnly () {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, 'readonly')) return true
      return false
    }
  },

  mounted () {
    this.checked = this.value
  },

  updated () {
    this.checked = this.value
  },

  methods: {
    onToogle () {
      if (this.disabled || this.isReadOnly) return

      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  }
}
</script>

<style scoped lang="scss">
  .el-check {
    transition: 0.2s;
    @apply relative;

    &.no-transition {
      transition: none;
    }
    &.checked {
      &:after {
        content: "";
        @apply absolute border-b-2 border-r-2 border-white;
        top: 2px;
        left: 5px;
        height: 9px;
        width: 4px;
        transform: rotate(45deg);
      }
      &.disabled {
        @apply bg-grey-200;
      }
    }
  }
  .disabled {
    @apply text-grey-200 cursor-not-allowed;
  }
</style>
